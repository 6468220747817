$(function() {

    $("#main-carousel").owlCarousel({
      items: 1,
      loop: true,
      nav: false,
      dots: false,
      autoplay: true,
      autoplayTimeout: 4000,
      autoplayHoverPause: true   
    });

    $(".fancybox").fancybox();
    $(".fancybox-thumbs").fancybox({
        prevEffect: 'none',
        nextEffect: 'none',
        openEffect: 'elastic',
        openSpeed: 150,
        closeEffect: 'elastic',
        closeSpeed: 150,
        closeClick: true,
        helpers: {
            title: {
                type: 'outside'
            },
            thumbs: {
                width: 50,
                height: 50
            }
        }
    });
    
	var $navbar = $(".navbar"),
	headerHeight = $(".header__login").outerHeight(),
	navbarHeight = $(".navbar-wrapper").outerHeight(),
	$navbarLink = $(".navbar-nav li a");
	
	$navbar.affix({
		offset: headerHeight
	});

	if($('.header__logo').css('display') !== 'none' ) {
		$('.navbar-brand').hide();
	}
	$.fn.logoShow = function() {
		if($('.header__logo').css('display') !== 'none' ) {
			logoHeight = $(".header__logo").outerHeight() + $(".header__logo").position().top;
			if ($(window).scrollTop() > logoHeight) {
				$('.navbar-brand').fadeIn();
			} else {
				if($('.header__logo').css('display') !== 'none' ) {
					$('.navbar-brand').fadeOut();
				}
			}
		} else {
			$('.navbar-brand').fadeIn();
		}
	};

	$.fn.myResize = function() {
		headerHeight = $(".header__login").outerHeight();
		$navbar.data('bs.affix').options.offset = headerHeight;
		return true;
	}; 
	/*
	var nxtY = (moment().year() + 1);
    $('#datetimepicker12').datetimepicker({
        inline: true,
        format: 'DD/MMM/YYYY',
        locale: 'cs',
        minDate: '2000',
        maxDate: '12-31-'+String(nxtY)
    });*/
});

$(window).scroll(function () {
	$.fn.logoShow();
});

$(window).resize(function() {
	$.fn.myResize();
	$.fn.logoShow();
});	

$(".header__login").on("webkitTransitionEnd transitionend oTransitionEnd", function () {
	$.fn.myResize();
});

$('#main-nav').on('show.bs.collapse', function(){
    $(".header__logo").toggleClass( "header__logo-hidden", 300);
});
$('#main-nav').on('shown.bs.collapse', function(){
	$.fn.logoShow();	
});
$('#main-nav').on('hide.bs.collapse', function(){
    $(".header__logo").toggleClass( "header__logo-hidden", 300);
});
$('#main-nav').on('hidden.bs.collapse', function(){
    $.fn.logoShow();	
});

$('#login-form').on('show.bs.collapse', function(){
    $(".header__logo").toggleClass( "header__logo-hidden2", 300);
});
$('#login-form').on('shown.bs.collapse', function(){
	$.fn.logoShow();	
});
$('#login-form').on('hide.bs.collapse', function(){
    $(".header__logo").toggleClass( "header__logo-hidden2", 300);
});
$('#login-form').on('hidden.bs.collapse', function(){
    $.fn.logoShow();
});

$( window ).resize(function() {
	if($(".navbar-wrapper .navbar-toggle[aria-expanded='true']").is(":hidden")) {
		$(".header__logo").removeClass( "header__logo-hidden");
	} else if($(".navbar-wrapper .navbar-toggle[aria-expanded='true']").is(":visible")) {
		$(".header__logo").addClass( "header__logo-hidden");		
	}
	if($("#login-form__btn[aria-expanded='true']").is(":hidden")) {
		$(".header__logo").removeClass( "header__logo-hidden2");
	} else if($("#login-form__btn[aria-expanded='true']").is(":visible")) {
		$(".header__logo").addClass( "header__logo-hidden2");		
	}
});